import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Paragraph, Theme } from '@lux/components-gomo';
import { FormItem } from '../common';
import nationalities from '../../constants/nationalities.json';
import genders from '../../constants/genders.json';
import { ID_TYPE_OPTIONS as idTypes } from '../../constants/identification_types.json';
import { Anchor } from '../../components/tracked-components';
import { capitalize } from '../../helpers/formatter';
import { formatFloorAndUnit, formatBlkAndStreet } from '../../helpers/address';
import { SectionDivider } from '../SectionDivider';
import { TitleSection } from '../UserDetailsFields/TitleSection';
import { ORDER_SUMMARY } from '../../constants/page_content.json';

const Container = styled.div`
  padding-top: ${Theme.spacing.medium};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfirmUserDetails = props => {
  const nationality = nationalities.find(n => n.value === props.nationality);
  const gender = genders.find(n => n.value === props.gender);
  const passType = idTypes.find(n => n.value === props.idType);
  const blkAndStreet = formatBlkAndStreet(props.billingAddress);
  const floorAndUnit = formatFloorAndUnit(props.billingAddress);

  return (
    <Container>
      <StyledRow>
        <TitleSection title={ORDER_SUMMARY.SECTION_HEADING.PERSONAL_DETAILS} />
        <Anchor onClick={() => navigate('/user-details/')}>EDIT</Anchor>
      </StyledRow>
      <FormItem label="Full Name" readOnly>
        <Paragraph>{capitalize(props.name)}</Paragraph>
      </FormItem>
      <FormItem label="Gender" readOnly>
        <Paragraph>{gender?.text}</Paragraph>
      </FormItem>
      <FormItem label="Date of Birth" readOnly>
        <Paragraph>{props.dateOfBirth}</Paragraph>
      </FormItem>
      <FormItem label={passType?.text} readOnly>
        <Paragraph>{props.idNo}</Paragraph>
      </FormItem>
      <FormItem label="Nationality" readOnly>
        <Paragraph>{nationality && nationality.text}</Paragraph>
      </FormItem>

      {props.passStatus && (
        <FormItem label="ID Status" readOnly>
          <Paragraph>{props.passStatus}</Paragraph>
        </FormItem>
      )}

      {props.passExpiryDate && (
        <FormItem label="ID expiry date" readOnly>
          <Paragraph>{props.passExpiryDate}</Paragraph>
        </FormItem>
      )}

      <FormItem label={'Billing address'} readOnly flexHeight>
        <StyledAddressContainer>
          <Paragraph>{blkAndStreet}</Paragraph>
          <Paragraph>{floorAndUnit}</Paragraph>
          <Paragraph>Singapore {props.billingAddress.postalCode}</Paragraph>
        </StyledAddressContainer>
      </FormItem>

      <SectionDivider top={8} />
    </Container>
  );
};

ConfirmUserDetails.defaultProps = {
  name: '',
  gender: '',
  idNo: '',
  idType: '',
  nationality: '',
  dateOfBirth: '',
  billingAddress: {}
};

ConfirmUserDetails.propTypes = {
  /** Name of customer */
  name: PropTypes.string,
  /** Gender */
  gender: PropTypes.string,
  /** ID number of customer */
  idNo: PropTypes.string,
  /** ID type */
  idType: PropTypes.string,
  /** Nationality of customer */
  nationality: PropTypes.string,
  /** Date of birth of customer */
  dateOfBirth: PropTypes.string,
  /** Billing address */
  billingAddress: PropTypes.shape({
    /** Block number */
    blockNumber: PropTypes.string,
    /** Floor number */
    floorNumber: PropTypes.string,
    /** Unit number */
    unitNumber: PropTypes.string,
    /** Street */
    street: PropTypes.string,
    /** Postal code */
    postalCode: PropTypes.string
  })
};

export default ConfirmUserDetails;
