import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Paragraph } from '@lux/components-gomo';
import { FormItem } from '../common';
import { Anchor } from '../tracked-components';
import { SectionDivider } from '../SectionDivider';
import { TitleSection } from '../UserDetailsFields/TitleSection';
import { ORDER_SUMMARY } from '../../constants/page_content.json';

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ConfirmContactDetails = props => {
  return (
    <>
      <StyledRow>
        <TitleSection title={ORDER_SUMMARY.SECTION_HEADING.CONTACT_DETAILS} />
        <Anchor onClick={() => navigate('/user-details/')}>EDIT</Anchor>
      </StyledRow>
      <FormItem
        label="Email"
        readOnly
        hintMessage={ORDER_SUMMARY.CONTACT_DETAILS_SECTION.EMAIL_HINT_MESSAGE}
      >
        <Paragraph>{props.email}</Paragraph>
      </FormItem>
      <FormItem label="Contact Number" readOnly>
        <Paragraph>{props.contactNo}</Paragraph>
      </FormItem>

      <SectionDivider top={8} />
    </>
  );
};

ConfirmContactDetails.defaultProps = {
  email: '',
  contactNo: ''
};

ConfirmContactDetails.propTypes = {
  /** Email of customer */
  email: PropTypes.string,
  /** Contact number of customer */
  contactNo: PropTypes.string
};

export default ConfirmContactDetails;
