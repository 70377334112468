import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Heading,
  Paragraph,
  GenericModalContent,
  Theme,
  FormItem,
  Button,
  TextField,
  Anchor
} from '@lux/components-gomo';
import { Modal } from '../../components/tracked-components';
import { noop, remCalc } from '@lux/helpers';
// import { Button } from '../../components/tracked-components';

import { PROMO_CODE_RESPONSE_CODES } from '../../constants/response_types.json';
import CHARGES from '../../constants/charges.json';
import { GOMO_5G_PAGE } from '../../constants/links.json';
import IconAdd from '../../assets/svgs/add.svg';
import {
  calcultateOffers,
  getOneTimeChargeDisplayValue,
  getPlanPrice,
  showBonusDataDescription
} from '../../helpers/charges';
import { GTM_DATALAYER_EVENTS } from '../../constants/gtmDataLayerEvents';
import { CTA_TEXT } from '../../constants/page_content.json';
import { getErrorPopupDataLayerVars } from '../../helpers/datalayer';

const newBlue = '#E8F9FF';
const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${p => (p.withBg ? newBlue : '')};
  padding: ${p => (p.withBg ? '8px 12px 0px 12px' : p.customPaddingSm)};
  margin: ${p => (p.wrapper ? '18px 0' : `${remCalc(7)} 0`)};
  margin: ${p => p.customMarginSm};
  ${Theme.media.md`
    padding: ${p => p.customPaddingMd};
    margin: ${p => p.customMarginMd};
  `};
`;

const StyledText = styled(Paragraph)`
  text-decoration: ${p => (p.strikeThrough ? 'line-through' : 'none')};
  font-weight: ${p => (p.bold ? '600' : '400')};
  margin-left: ${remCalc(3)};
  padding-right: ${p => (p.itemDesc ? Theme.spacing.large : 0)};
  max-width: ${p => !p.fullWidth && '200px'};
  font-size: ${p => (p.small ? Theme.fonts.sizes.xsmall : '')};
  ${Theme.media.md`
    max-width: ${p => !p.fullWidth && '425px'};
    font-size: ${p => (p.small ? Theme.fonts.sizes.xsmall : '')};
  `};
`;

const StyledAnchor = styled(Anchor)`
  color: ${Theme.colours.gomo_cyan};
`;

const InlineFormItem = styled.div`
  flex: 1;
  min-width: 170px;
  margin-right: 32px;
`;

const StyledDesc = styled(Paragraph)`
  margin-left: ${remCalc(3)};
  padding: ${remCalc(10)} 0;
  padding-right: ${Theme.spacing.small};
  @media (min-width: 48em) {
    font-size: 0.875rem;
  }
  @media (min-width: 56em) {
    font-size: 1rem;
  }
`;

const StyledHeading = styled(Heading)`
  color: ${Theme.colours.gomo_black_33};
  margin: 0;
`;

const PayNowColumn = styled.div`
  display: flex;
  flex-direction: column;
  white-space: wrap;
  justify-content: center;
`;

const AmountCol = styled.div`
  display: flex;
`;

const TotalAmountCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
  align-items: end;
`;

const StyledPromoMessageRow = styled(StyledRow)`
  margin-top: -20px;
  margin-bottom: 30px;
`;

class Bill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: '',
      errorMessage: '',
      showInvalidTransactionTypePromoErrorSection: false,
      isShowingOTC: true
    };
    this.handlePromoButton = this.handlePromoButton.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let errorMessage = '';
    let showInvalidTransactionTypePromoErrorSection = false;
    let promoCode = '';
    let listError = ['404', '406', '410', '412', '419'];
    if (
      nextProps.retrievePromoCodeError &&
      listError.includes(nextProps.retrievePromoCodeError)
    ) {
      errorMessage = PROMO_CODE_RESPONSE_CODES[nextProps.retrievePromoCodeError]
        ? PROMO_CODE_RESPONSE_CODES[nextProps.retrievePromoCodeError].message
        : '';
    }
    if (nextProps.promoCodeDetails && nextProps.promoCodeDetails.promoCode) {
      promoCode = nextProps.promoCodeDetails.promoCode;
      return { errorMessage, promoCode };
    }
    if (!state.promoCode && nextProps.retrievePromoCodeError) {
      nextProps.handleModalClose('RETRIEVE_PROMO_CODE');
    }

    // When user hits 406 error at promo code validation
    // then Show invalid transaction type promo error section
    if (nextProps.retrievePromoCodeError === '406') {
      showInvalidTransactionTypePromoErrorSection = true;
    }

    return {
      errorMessage,
      showInvalidTransactionTypePromoErrorSection
    };
  }

  componentDidMount() {
    if (
      this.props.queryString &&
      this.props.queryString.promocode &&
      !this.state.promoCode
    ) {
      this.setState({ promoCode: this.props.queryString.promocode });
      this.props.validatePromoCodeHandler(this.props.queryString.promocode);
      this.props.removeQueryString('promocode');
    }
  }

  handleInputChange = (event, input) => {
    if (this.state.errorMessage.length)
      this.props.handleModalClose('RETRIEVE_PROMO_CODE');
    this.setState({
      ...this.state,
      [input]: event.target.value,
      errorMessage: ''
    });
  };

  onClickHandler = () => {
    let validatePromocode = this.state.promoCode.trim();
    this.setState({ promoCode: validatePromocode });
    this.props.validatePromoCodeHandler(validatePromocode);
  };

  renderOffers = offers => {
    let offersList = null;
    let showBonusDataDesc = false;

    if (offers && offers.length) {
      offersList = offers.map(o => {
        if (!showBonusDataDesc) {
          showBonusDataDesc = showBonusDataDescription(o.description);
        }
        return (
          <StyledText
            key={o.description}
            itemDesc
            data-testid="promo-description"
          >
            {o.description}
          </StyledText>
        );
      });
      return (
        <>
          <StyledRow wrapper>
            <IconAdd width={24} height={24} data-testid="promo-add-icon" />
          </StyledRow>
          <StyledText bold small data-testid="promo-code-sub-heading">
            ‘{this.state.promoCode}’
          </StyledText>
          {offersList}
          {showBonusDataDesc && (
            <StyledRow customMarginSm="16px 0 0">
              <StyledText small fullWidth data-testid="bonus-data-description">
                {CHARGES.BONUS_DATA_DESCRIPTION}
              </StyledText>
            </StyledRow>
          )}
        </>
      );
    }
    return null;
  };

  renderRetrievePromoCodeError = code => {
    if (!code) return null;

    const validErrorCodes = ['401', '402', '403', 'default'];
    const errorRetrievingPromoCode = validErrorCodes.includes(code);

    if (errorRetrievingPromoCode) {
      const errorModalDataLayerVars = getErrorPopupDataLayerVars({
        event: GTM_DATALAYER_EVENTS.PROCEED_TO_PAY,
        errorName: PROMO_CODE_RESPONSE_CODES.default.message,
        eventLabel: CTA_TEXT.CLOSE
      });

      return (
        <Modal
          onModaRender={noop}
          onModalClose={() =>
            this.props.handleModalClose('RETRIEVE_PROMO_CODE')
          }
          renderProp={({ onModalClose }) => (
            <GenericModalContent
              theme="hybrid"
              headerText="OOPS"
              paragraphText={PROMO_CODE_RESPONSE_CODES.default.message}
              buttonText={CTA_TEXT.CLOSE}
              onModalClose={onModalClose}
            />
          )}
          trackEvent={{
            modalRender: errorModalDataLayerVars.render,
            modalClose: errorModalDataLayerVars.buttonClick
          }}
        />
      );
    }
    return null;
  };

  removePromocode = () => {
    this.setState({ promoCode: '', errorMessage: '', isShowingOTC: true });
    this.props.handlePromoCodeClear();
  };

  handlePromoButton = () => {
    if (!this.props.promoCodeDetails) {
      const isEmptyPromoCode = !this.state.promoCode;

      return (
        <Button
          type="secondary"
          onClick={this.onClickHandler}
          disabled={isEmptyPromoCode}
        >
          Add
        </Button>
      );
    } else {
      return (
        <Button type="secondary" onClick={this.removePromocode}>
          Remove
        </Button>
      );
    }
  };

  getFinalPriceText = () => {
    let newPrice = 0;
    let finalPriceWithoutOtc = 0;
    const hasPromoCodeDetailsGiven = this.props.promoCodeDetails !== null;
    const {
      monthlyFee,
      oneTimeChargeDetails,
      promoCodeDetails,
      setFinalPrice,
      setFinalPriceWithoutOtc
    } = this.props;

    const { isShowingOTC } = this.state;
    const otcPrice = oneTimeChargeDetails ? oneTimeChargeDetails.value : 0;
    if (hasPromoCodeDetailsGiven) {
      const promoPrice = calcultateOffers(promoCodeDetails.offers);
      const sumWithOTC = monthlyFee + otcPrice + promoPrice;
      const sumWithoutOTC = monthlyFee + promoPrice;
      finalPriceWithoutOtc = sumWithoutOTC;
      if (sumWithOTC > 0) {
        if (!isShowingOTC) this.setState({ isShowingOTC: true });
        newPrice = sumWithOTC;
      } else {
        if (isShowingOTC) this.setState({ isShowingOTC: false });
        newPrice = sumWithoutOTC;
      }
    } else {
      const sumWithoutPromo = monthlyFee + otcPrice;
      finalPriceWithoutOtc = monthlyFee;
      if (sumWithoutPromo > 0) {
        if (!isShowingOTC) this.setState({ isShowingOTC: true });
        newPrice = sumWithoutPromo;
      } else {
        if (isShowingOTC) this.setState({ isShowingOTC: false });
        newPrice = monthlyFee;
      }
    }
    if (newPrice !== this.props.price) {
      setFinalPrice(newPrice);
      setFinalPriceWithoutOtc(finalPriceWithoutOtc);
    }
    return `${CHARGES.CURRENCY}${newPrice.toFixed(2)}`;
  };

  render() {
    const { oneTimeChargeDetails, monthlyFee, promoCodeDetails } = this.props;
    const { isShowingOTC } = this.state;
    const planPrice = getPlanPrice(
      monthlyFee,
      promoCodeDetails?.buyflowPriceAfterDiscount
    );
    let oneTimeCharge = getOneTimeChargeDisplayValue(
      oneTimeChargeDetails?.value
    );

    return (
      <>
        {/* Plan Details section */}
        <StyledRow customPaddingSm="16px 0 10px">
          <StyledText bold>{CHARGES.PLAN_DETAILS_HEADING}</StyledText>
          <StyledText bold data-testid="plan-price">{`${
            CHARGES.CURRENCY
          }${planPrice}`}</StyledText>
        </StyledRow>
        <StyledText bold small>
          {CHARGES.SIM_ONLY_PLAN.NAME}
        </StyledText>
        <StyledText itemDesc>{CHARGES.SIM_ONLY_PLAN.DESCRIPTION}</StyledText>

        {/* Promo Details section */}
        {promoCodeDetails !== null
          ? this.renderOffers(promoCodeDetails.offers)
          : null}
        <StyledRow wrapper customPaddingSm="6px 0 0">
          <InlineFormItem>
            <FormItem
              label={this.props.promoCodeAnchorText}
              errorMessage={this.state.errorMessage}
            >
              <TextField
                type="input"
                readOnly={promoCodeDetails !== null ? true : false}
                value={this.state.promoCode}
                placeholder="Eg. ABC12345"
                onChange={event => this.handleInputChange(event, 'promoCode')}
              />
            </FormItem>
          </InlineFormItem>
          {this.handlePromoButton()}
        </StyledRow>
        {this.state.showInvalidTransactionTypePromoErrorSection && (
          <StyledPromoMessageRow wrapper>
            <Paragraph data-testid="invalid-transaction-type-promo-error-section">
              Existing Singtel customer? Check out our{' '}
              <Anchor href={GOMO_5G_PAGE.ABSOLUTE} target="_blank">
                5G deals
              </Anchor>{' '}
              for other promo codes.
            </Paragraph>
          </StyledPromoMessageRow>
        )}
        {this.renderRetrievePromoCodeError(this.props.retrievePromoCodeError)}

        {/* One time charge Details */}
        {oneTimeChargeDetails && isShowingOTC ? (
          <>
            <StyledRow>
              <StyledText bold>{CHARGES.ONE_TIME_CHARGE.NAME}</StyledText>
              <AmountCol>
                <StyledText bold data-testid="one-time-charge-value">
                  {oneTimeCharge}
                </StyledText>
              </AmountCol>
            </StyledRow>
            <StyledRow customPaddingSm="0 0 16px">
              <StyledText itemDesc data-testid="one-time-charge-description">
                {oneTimeChargeDetails.description}
              </StyledText>
            </StyledRow>
          </>
        ) : null}

        {/* Total section */}
        <StyledRow withBg>
          <PayNowColumn>
            <StyledText bold>Total</StyledText>
          </PayNowColumn>
          <TotalAmountCol>
            <StyledHeading level={3}>{this.getFinalPriceText()}</StyledHeading>
            <StyledText small>{CHARGES.TOTAL_DESCRIPTION}</StyledText>
          </TotalAmountCol>
        </StyledRow>
        <StyledRow>
          <PayNowColumn>
            <StyledDesc>
              {this.getFinalPriceText()} will be charged to your card today.
              <br /> Your first month billing cycle will be{' '}
              {CHARGES.DAYS_TO_NEXT_BILLING_CYCLE} days, and subsequent months
              will be every {CHARGES.DAYS_TO_RECURRING_PAYMENT} days thereafter.
            </StyledDesc>
          </PayNowColumn>
        </StyledRow>
      </>
    );
  }
}

Bill.defaultProps = {
  selectedNumber: '',
  monthlyFee: '',
  regFee: '',
  delFee: '',
  nextChargeDate: '',
  isSelfCollection: false
};

Bill.propTypes = {
  /** Selected mobile number */
  selectedNumber: PropTypes.string,
  /** Monthly fee of plan */
  monthlyFee: PropTypes.string,
  /** Registration fee of plan */
  regFee: PropTypes.string,
  /** Delivery fee of plan */
  delFee: PropTypes.string,
  /** Next charge date of plan */
  nextChargeDate: PropTypes.string,
  /** Is Self Collection from popstation */
  isSelfCollection: PropTypes.bool,
  /** Is Promo Code modal open */
  isPromoModalOpen: PropTypes.bool,
  /** Text copy for Promo code link */
  promoCodeAnchorText: PropTypes.string,
  /** Promo code details */
  promoCodeDetails: PropTypes.object,
  /** Function to remove Promo code previously added */
  queryString: PropTypes.object,
  /** promocode from querystring added */
  handlePromoCodeClear: PropTypes.func,
  /** Error handle for Promo code */
  removeQueryString: PropTypes.func,
  /** remove promocode after apply */
  handlePromoCodeError: PropTypes.func,
  /** Function to close Promo code modal */
  handlePromoModalClose: PropTypes.func,
  /** Function to close modal for error message */
  handleModalClose: PropTypes.func,
  /** Function to validate Promo code added */
  validatePromoCodeHandler: PropTypes.func,
  /** Function to handle displaying of Promo code modal */
  handlePromoModalShow: PropTypes.func
};

export default Bill;
