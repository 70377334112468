import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Heading, GenericModalContent } from '@lux/components-gomo';
import { isToday, isBefore } from 'date-fns';
import { navigate } from 'gatsby';
import { noop } from '@lux/helpers';
import actions from '../../actions';
import {
  formatMobileNumber,
  getCallbarringTypes
} from '../../helpers/mobileNumber';
import { formatFloorAndUnit, formatBlkAndStreet } from '../../helpers/address';
import { isBeforeCutOff, getDateAfterDays } from '../../helpers/datetime';

import { MONTH } from '../../constants/gregorian_calendar_terms.json';
import { MOBILE_NUMBER_MODES } from '../../constants/transaction_codes.json';
import { ORDER_SUMMARY, CTA_TEXT } from '../../constants/page_content.json';

import Bill from '../Bill';
import NavControls from '../../components/NavControls';
import ConfirmDelDetails from '../../components/ConfirmDelDetails';
import ConfirmUserDetails from '../../components/ConfirmUserDetails';
import ConfirmConsent from '../../components/ConfirmConsent';
import withPromoCodeApiFailureModal from '../withPromoCodeApiFailureModal';
import withRedirect from '../withRedirect';
import { Modal } from '../../components/tracked-components';
import { createLoadingSelector } from '../../selectors';
import ConfirmContactDetails from '../../components/ConfirmContactDetails';
import { isEsim } from '../../helpers/userDetailsForm';
import {
  GTM_DATALAYER_EVENTS,
  GTM_EVENT_ACTIONS,
  GTM_EVENT_CATEGORIES
} from '../../constants/gtmDataLayerEvents';
import { getErrorPopupDataLayerVars } from '../../helpers/datalayer';
import { THEME } from '../../constants/app_configuration';

const submitOrderSelector = createLoadingSelector(['SUBMIT_ORDER']);

const mapStateToProps = state => {
  return {
    userDetails: state.userDetails,
    deliveryDetails: state.deliveryDetails,
    selectedNumber: state.selectedNumber,
    authToken: state.auth.token,
    submitOrderLoading: submitOrderSelector(state),
    selectedPopstation: state.selfCollection.selectedPopstation,
    isSelfCollection: state.selfCollection.isSelfCollection,
    selectedSimType: state.simTypeDetails.selectedSimType,
    isEsimSelected: isEsim(state.simTypeDetails.selectedSimType),
    promoCodeDetails: state.promoCode.details,
    oneTimeChargeDetails: state.oneTimeCharge.details?.oneTimeCharges,
    basePlanPrice: state.oneTimeCharge.details?.basePlanPrice || 0,
    retrievePromoCodeError: state.error.RETRIEVE_PROMO_CODE,
    queryString: state.handleQueryString.queryString || {},
    error: state.error,
    loading: state.loading
  };
};

const mapDispatchToProps = dispatch => {
  const {
    submitOrder,
    retrievePromoCodeClear,
    clearError,
    retrievePromoCode,
    getOneTimeCharge,
    removeQueryString,
    pushEventToDataLayer
  } = actions;

  return {
    submitOrder: payload => dispatch(submitOrder(payload)),
    retrievePromoCodeClear: () => dispatch(retrievePromoCodeClear()),
    clearError: errorKey => dispatch(clearError(errorKey)),
    retrievePromoCode: (voucherCode, mobileNumber, authToken, txnType) =>
      dispatch(
        retrievePromoCode(voucherCode, mobileNumber, authToken, txnType)
      ),
    getOneTimeCharge: (challengeCode, mobileNumber, selectedSimType) =>
      dispatch(getOneTimeCharge(challengeCode, mobileNumber, selectedSimType)),
    removeQueryString: payload => dispatch(removeQueryString(payload)),
    pushEventToDataLayer: payload => dispatch(pushEventToDataLayer(payload))
  };
};

export class ConfirmDetails extends Component {
  state = {
    nextChargeDate: '',
    isAfterCutOffModalOpen: false,
    isPromoModalOpen: false,
    isdeliveryDetailsErrorModalOpen: false,
    finalPrice: 0,
    hasBasePlanPriceError: false,
    tncAgreed: false
  };

  static getDerivedStateFromProps(nextProps, state) {
    const hasError =
      nextProps.basePlanPrice === 0 || nextProps.error?.GET_ONE_TIME_CHARGE;
    return { hasBasePlanPriceError: hasError };
  }

  componentDidMount() {
    this.setState({ nextChargeDate: this.determineNextChargeDate() });
    const { selectedNumber, authToken, selectedSimType } = this.props;
    this.props.getOneTimeCharge(
      authToken,
      selectedNumber.number,
      selectedSimType
    );
  }

  isBeforeCutOff = () => {
    const cutOffDate = new Date();
    cutOffDate.setHours(11);
    cutOffDate.setMinutes(0);
    cutOffDate.setMilliseconds(0);
    return isBefore(new Date(), cutOffDate);
  };

  handleModalDeliveryDetailClose = () => {
    this.setState({ isdeliveryDetailsErrorModalOpen: false });
    navigate('/select-number/');
  };

  handleNextButtonClick = () => {
    if (
      !this.props.isEsimSelected &&
      (!this.props.deliveryDetails.date ||
        !this.props.deliveryDetails.code ||
        !this.props.deliveryDetails.time)
    ) {
      return this.setState({ isdeliveryDetailsErrorModalOpen: true });
    }
    if (this.props.retrievePromoCodeError) {
      this.setState({ isPromoCodeError: true });
    } else {
      this.handleCheckout();
    }
  };

  handleCheckout = () => {
    const isSameDayDel = isToday(new Date(this.props.deliveryDetails.date));

    const shouldSubmitOrder =
      (isSameDayDel && isBeforeCutOff()) || !isSameDayDel;
    if (shouldSubmitOrder) {
      let updatedSelectedNumber = Object.assign({}, this.props.selectedNumber);
      delete updatedSelectedNumber.callBarringType;

      const payload = {
        userDetails: {
          ...this.props.userDetails,
          oneTimeChargeKey: this.props.oneTimeChargeDetails.key || '',
          price: this.state.finalPrice.toFixed(2) || '0.00',
          priceWithoutOneTimeCharge: +(
            this.state.finalPriceWithoutOtc.toFixed(2) || '0.00'
          )
        },
        deliveryDetails: this.props.deliveryDetails,
        selectedNumber: updatedSelectedNumber,
        selectedSimType: this.props.selectedSimType,
        authToken: this.props.authToken,
        selectedPopstation: this.props.selectedPopstation,
        promoCodeDetails: this.props.promoCodeDetails,
        queryString: this.props.queryString,
        barringType: getCallbarringTypes(
          this.props.selectedNumber.callBarringType
        )
      };
      //add GTM tracking
      this.props.pushEventToDataLayer({
        event: GTM_DATALAYER_EVENTS.PROCEED_TO_PAY,
        eventCategory: GTM_EVENT_CATEGORIES.CONTENT_CLICK,
        eventAction: GTM_EVENT_ACTIONS.BUTTON_CLICK,
        eventLabel: CTA_TEXT.PROCEED_TO_PAY
      });
      this.props.submitOrder(payload);
    } else {
      this.setState({ isAfterCutOffModalOpen: true });
    }
  };

  determineNextChargeDate = () => {
    const chargeDate = getDateAfterDays(30);
    const date = chargeDate.getDate();
    const month = MONTH.MEDIUM[chargeDate.getMonth()];
    return `${date} ${month}`;
  };

  handlePromoModalClose = () => {
    this.setState({ isPromoModalOpen: false });
  };

  handleModalProceedClose = type => {
    this.props.clearError(type);
    this.setState({ isPromoCodeError: false });
  };
  handleModalProceedCloseAndCheckout = () => {
    this.setState({ isPromoCodeError: false });
    this.handleCheckout();
  };

  handleOntimeChargesErrorModalClose = () => {
    this.setState({ hasBasePlanPriceError: false });
    const { selectedNumber, authToken, selectedSimType } = this.props;
    this.props.getOneTimeCharge(
      authToken,
      selectedNumber.number,
      selectedSimType
    );
  };

  handleModalClose = type => {
    this.props.clearError(type);
  };

  validatePromoCodeHandler = promoCodeValue => {
    const { selectedNumber, authToken, retrievePromoCode } = this.props;
    let txnType = '';

    switch (selectedNumber.mode) {
      case 'portIn':
        txnType = MOBILE_NUMBER_MODES.PORT_IN;
        break;
      case 'reserveNumber':
        txnType = MOBILE_NUMBER_MODES.NEW;
        break;
      default:
        console.log(
          'Error: A transaction type for promo code retrieval is unavailable for the selected number mode'
        );
    }

    retrievePromoCode(
      promoCodeValue,
      selectedNumber.number,
      authToken,
      txnType
    );
    this.handlePromoModalClose();
  };

  handlePromoModalShow = () => {
    this.setState({ isPromoModalOpen: true });
  };

  handlePromoCodeClear = () => {
    const { retrievePromoCodeClear } = this.props;
    retrievePromoCodeClear();
  };

  handlePromoCodeError = () => {
    const { retrievePromoCodeError } = this.props;
    retrievePromoCodeError();
  };

  handleApiErrorModalClose = () => {
    this.setState({ isApiErrorModalOpen: false });
  };

  setFinalPrice = price => {
    this.setState({ finalPrice: price });
  };

  setFinalPriceWithoutOtc = price => {
    this.setState({ finalPriceWithoutOtc: price });
  };

  handleTncCheckboxChange = () => {
    this.setState({ tncAgreed: !this.state.tncAgreed });
  };

  shouldDisableNextButton = () => {
    return this.props.submitOrderLoading || !this.state.tncAgreed;
  };

  renderDeliveryDetails = () => {
    const {
      userDetails,
      selectedPopstation,
      deliveryDetails,
      isSelfCollection,
      isEsimSelected
    } = this.props;

    if (isSelfCollection) {
      return (
        <ConfirmDelDetails
          stationName={selectedPopstation.POPStationName}
          address={`${selectedPopstation.HouseBlockNumber} ${
            selectedPopstation.StreetName
          }, Singapore ${selectedPopstation.ZipCode}`}
          location={selectedPopstation.Location}
          delDate={deliveryDetails.date}
          isSelfCollection={true}
        />
      );
    } else if (isEsimSelected) {
      return (
        <ConfirmDelDetails email={userDetails.email} isEsimSelected={true} />
      );
    }
    return (
      <ConfirmDelDetails
        blkAndStreet={formatBlkAndStreet(deliveryDetails.address)}
        floorAndUnit={formatFloorAndUnit(deliveryDetails.address)}
        blockNumber={deliveryDetails.address.floorNumber}
        postalCode={deliveryDetails.address.postalCode}
        delDate={deliveryDetails.date}
        delTime={deliveryDetails.time}
      />
    );
  };

  render() {
    const {
      selectedNumber,
      userDetails,
      isSelfCollection,
      promoCodeDetails,
      oneTimeChargeDetails,
      basePlanPrice,
      retrievePromoCodeError,
      queryString,
      removeQueryString,
      loading
    } = this.props;

    const {
      basePricePlanError: basePricePlanErrorContent,
      deliveryError: deliveryErrorContent,
      sameDayDeliveryError: sameDayDeliveryErrorContent
    } = ORDER_SUMMARY.MODAL_CONTENT;

    const deliveryErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.PROCEED_TO_PAY,
      errorName: deliveryErrorContent.paragraphText,
      eventLabel: CTA_TEXT.OK_GOT_IT
    });

    const sameDayDeliveryErrorDataLayerVars = getErrorPopupDataLayerVars({
      event: GTM_DATALAYER_EVENTS.PROCEED_TO_PAY,
      errorName: sameDayDeliveryErrorContent.paragraphText,
      eventLabel: CTA_TEXT.OK_GOT_IT
    });

    return (
      <Fragment>
        <Heading level={4} secondary>
          Order Summary
        </Heading>
        <Heading level={2}>Mobile No. {selectedNumber.number}</Heading>
        <Bill
          selectedNumber={formatMobileNumber(selectedNumber.number)}
          nextChargeDate={this.state.nextChargeDate}
          monthlyFee={basePlanPrice}
          regFee="$48.15"
          delFee="$16.00"
          promo="$20.00"
          stationFee="$8.00"
          oneTimeChargeDetails={oneTimeChargeDetails}
          isSelfCollection={isSelfCollection || false}
          isPromoModalOpen={this.state.isPromoModalOpen}
          promoCodeAnchorText="Have a promo code?"
          promoCodeDetails={promoCodeDetails}
          retrievePromoCodeError={retrievePromoCodeError}
          handlePromoCodeClear={this.handlePromoCodeClear.bind(this)}
          handlePromoCodeError={this.handlePromoCodeError.bind(this)}
          handlePromoModalClose={this.handlePromoModalClose.bind(this)}
          handleModalClose={this.handleModalClose.bind(this)}
          validatePromoCodeHandler={this.validatePromoCodeHandler.bind(this)}
          handlePromoModalShow={this.handlePromoModalShow.bind(this)}
          removeQueryString={removeQueryString}
          queryString={queryString}
          price={this.state.finalPrice}
          setFinalPrice={this.setFinalPrice.bind(this)}
          setFinalPriceWithoutOtc={this.setFinalPriceWithoutOtc.bind(this)}
        />
        <ConfirmUserDetails
          name={`${userDetails.firstName} ${userDetails.lastName}`}
          idNo={userDetails.idNo}
          idType={userDetails.idType}
          nationality={userDetails.nationality}
          gender={userDetails.gender}
          dateOfBirth={userDetails.dateOfBirth}
          passStatus={userDetails.passStatus}
          passExpiryDate={userDetails.passExpiryDate}
          billingAddress={userDetails.address}
        />
        <ConfirmContactDetails
          email={userDetails.email}
          contactNo={userDetails.contactNo}
        />
        {this.renderDeliveryDetails()}
        <ConfirmConsent
          onTncCheckboxChange={this.handleTncCheckboxChange}
          tncAgreed={this.state.tncAgreed}
        />
        <NavControls
          nextButtonText="PROCEED TO PAY"
          disableNextButton={this.shouldDisableNextButton()}
          onNextButtonClick={this.handleNextButtonClick}
        />
        {this.state.hasBasePlanPriceError && !loading?.GET_ONE_TIME_CHARGE && (
          <Modal
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme={THEME}
                headerText={basePricePlanErrorContent.headerText}
                paragraphText={basePricePlanErrorContent.paragraphText}
                buttonText={CTA_TEXT.OK_GOT_IT}
                onModalClose={this.handleOntimeChargesErrorModalClose}
              />
            )}
          />
        )}
        {this.state.isPromoCodeError && (
          <Modal
            onModalClose={this.handleModalProceedClose}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme="hybrid"
                headerText="PROCEED TO PAY"
                paragraphText="Do you want to proceed without promo code?"
                anchorText="Add promo code"
                buttonText="PROCEED"
                onModalAnchorClick={onModalClose}
                onModalClose={this.handleModalProceedCloseAndCheckout}
              />
            )}
          />
        )}
        {this.state.isdeliveryDetailsErrorModalOpen && (
          <Modal
            onModalClose={this.handleModalDeliveryDetailClose}
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme={THEME}
                headerText={deliveryErrorContent.headerText}
                paragraphText={deliveryErrorContent.paragraphText}
                buttonText={CTA_TEXT.OK_GOT_IT}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: deliveryErrorDataLayerVars.render,
              modalClose: deliveryErrorDataLayerVars.buttonClick
            }}
          />
        )}
        {this.state.isAfterCutOffModalOpen && (
          <Modal
            onModalClose={this.handleModalClose}
            onModalRender={noop}
            renderProp={({ onModalClose }) => (
              <GenericModalContent
                theme={THEME}
                headerText={sameDayDeliveryErrorContent.headerText}
                paragraphText={sameDayDeliveryErrorContent.paragraphText}
                buttonText={CTA_TEXT.OK_GOT_IT}
                onModalClose={onModalClose}
              />
            )}
            trackEvent={{
              modalRender: sameDayDeliveryErrorDataLayerVars.render,
              modalClose: sameDayDeliveryErrorDataLayerVars.buttonClick
            }}
          />
        )}
      </Fragment>
    );
  }
}

const withRedirectHoc = withRedirect(ConfirmDetails);
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRedirectHoc);

export default withPromoCodeApiFailureModal(connected);
