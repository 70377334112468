import React, { Fragment, Component } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { GenericModalContent } from '@lux/components-gomo';
import actions from '../../actions';
import {
  PROMO_CODE_RESPONSE_CODES,
  BUYFLOW_RESPONE_CODES
} from '../../constants/response_types.json';
import { noop } from '@lux/helpers';

import { createErrorMessageSelector } from '../../selectors';
import { GTM_DATALAYER_EVENTS } from '../../constants/gtmDataLayerEvents';
import { getErrorPopupDataLayerVars } from '../../helpers/datalayer';
import { Modal } from '../../components/tracked-components';
import { CTA_TEXT } from '../../constants/page_content.json';
import { HOME_PAGE } from '../../constants/links.json';

const apiErrorSelector = createErrorMessageSelector(['SUBMIT_ORDER']);

export const mapStateToProps = state => {
  return {
    apiFailure: apiErrorSelector(state),
    authToken: state.auth.token,
    selectedNumber: state.selectedNumber.number,
    selectedSimType: state.simTypeDetails.selectedSimType
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    clearError: errorKey => dispatch(actions.clearError(errorKey)),
    getOneTimeCharge: (challengeCode, mobileNumber, selectedSimType) =>
      dispatch(
        actions.getOneTimeCharge(challengeCode, mobileNumber, selectedSimType)
      ),
    pushEventToDataLayer: payload =>
      dispatch(actions.pushEventToDataLayer(payload))
  };
};

export class PromoCodeApiFailureModal extends Component {
  handleModalClose = () => {
    if (
      this.props.apiFailure === BUYFLOW_RESPONE_CODES.MAXIMUM_LINE_COUNT_REACHED
    ) {
      navigate(HOME_PAGE.ABSOLUTE);
      return;
    }

    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }
    this.props.getOneTimeCharge(
      this.props.authToken,
      this.props.selectedNumber,
      this.props.selectedSimType
    );
    this.props.clearError('SUBMIT_ORDER');
  };

  render() {
    if (this.props.apiFailure) {
      const errorModalDataLayerVars = getErrorPopupDataLayerVars({
        event: GTM_DATALAYER_EVENTS.PROCEED_TO_PAY,
        errorName: PROMO_CODE_RESPONSE_CODES[this.props.apiFailure].message,
        eventLabel: CTA_TEXT.OK_GOT_IT
      });

      const headerText =
        PROMO_CODE_RESPONSE_CODES[this.props.apiFailure].headerText ||
        PROMO_CODE_RESPONSE_CODES.default.headerText;

      return (
        <Modal
          onModalClose={this.handleModalClose}
          onModalRender={noop}
          renderProp={({ onModalClose }) => (
            <GenericModalContent
              theme="hybrid"
              headerText={headerText}
              paragraphText={
                PROMO_CODE_RESPONSE_CODES[this.props.apiFailure].message
              }
              buttonText={CTA_TEXT.OK_GOT_IT}
              onModalClose={onModalClose}
            />
          )}
          trackEvent={{
            modalRender: errorModalDataLayerVars.render,
            modalClose: errorModalDataLayerVars.buttonClick
          }}
        />
      );
    }
    return null;
  }
}

const ConnectedModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoCodeApiFailureModal);

const withPromoCodeApiFailureModal = WrappedComponent => {
  return props => (
    <Fragment>
      <WrappedComponent {...props} />
      <ConnectedModal />
    </Fragment>
  );
};

export default withPromoCodeApiFailureModal;
