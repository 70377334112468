import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Paragraph } from '@lux/components-gomo';
import { FormItem } from '../common';
import { Anchor } from '../../components/tracked-components';
import { SectionDivider } from '../SectionDivider';
import { TitleSection } from '../UserDetailsFields/TitleSection';

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledParagraphBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInfoParagraph = styled(Paragraph)`
  padding-bottom: 24px;
`;

const ConfirmDelDetails = props => {
  const { isSelfCollection, isEsimSelected, email } = props;

  const heading = isSelfCollection
    ? 'POPStation Collection'
    : isEsimSelected
    ? 'eSIM delivery'
    : 'Doorstep Delivery';

  const showEditButton = !isEsimSelected;

  return (
    <>
      <StyledRow>
        <TitleSection title={heading} />
        {showEditButton && (
          <Anchor onClick={() => navigate('/delivery-details/')}>EDIT</Anchor>
        )}
      </StyledRow>

      {!isEsimSelected && (
        <>
          <FormItem
            label={isSelfCollection ? 'Collection address' : 'Delivery address'}
            readOnly
            flexHeight
          >
            <StyledParagraphBlock>
              {isSelfCollection ? (
                <>
                  <Paragraph>{props.stationName}</Paragraph>
                  <Paragraph>{props.address}</Paragraph>
                  <Paragraph>{props.location}</Paragraph>
                </>
              ) : (
                <>
                  <Paragraph>{props.blkAndStreet}</Paragraph>
                  <Paragraph>{props.floorAndUnit}</Paragraph>
                  <Paragraph>Singapore {props.postalCode}</Paragraph>
                </>
              )}
            </StyledParagraphBlock>
          </FormItem>
          <FormItem
            label={isSelfCollection ? 'Collection date' : 'Date and time'}
            readOnly
            flexHeight
          >
            <StyledParagraphBlock>
              <Paragraph>{props.delDate}</Paragraph>
              <Paragraph>{props.delTime}</Paragraph>
            </StyledParagraphBlock>
          </FormItem>
        </>
      )}

      {isEsimSelected && (
        <StyledInfoParagraph>
          Your eSIM will be sent to {email}.
        </StyledInfoParagraph>
      )}

      <SectionDivider top={8} />
    </>
  );
};

ConfirmDelDetails.defaultProps = {
  blkAndStreet: '',
  floorAndUnit: '',
  postalCode: '',
  delDate: '',
  delTime: '',
  isSelfCollection: false,
  isEsimSelected: false,
  email: ''
};

ConfirmDelDetails.propTypes = {
  /** Block and street number */
  blkAndStreet: PropTypes.string,
  /** Floor and unit number */
  floorAndUnit: PropTypes.string,
  /** Postal code */
  postalCode: PropTypes.string,
  /** Delivery date */
  delDate: PropTypes.string,
  /** Delivery time */
  delTime: PropTypes.string,
  /** Self Collection from popstation */
  isSelfCollection: PropTypes.bool,
  /** Flag to indicate if user selected eSIM */
  isEsimSelected: PropTypes.bool,
  /** Email of customer */
  email: PropTypes.string
};

export default ConfirmDelDetails;
