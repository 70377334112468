import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from '@lux/components-gomo';
import { noop } from '@lux/helpers';
import CheckBoxRow from '../CheckBoxRow';
import {
  TNC_LINK,
  DATA_PROTECTION_LINK,
  FAQ_PAGE
} from '../../constants/links.json';

const ConfirmConsent = ({ tncAgreed, onTncCheckboxChange }) => {
  const renderTncCheckboxDescription = () => {
    return (
      <div data-testid="tnc-checkbox-description">
        I confirm that the above information is accurate and agree to the GOMO's
        full{' '}
        <Anchor target="_blank" href={TNC_LINK.ABSOLUTE}>
          Terms and Conditions
        </Anchor>{' '}
        and the prevailing{' '}
        <Anchor target="_blank" href={DATA_PROTECTION_LINK.ABSOLUTE}>
          Singtel's Data Protection Policy.
        </Anchor>
      </div>
    );
  };

  return (
    <Fragment>
      <CheckBoxRow
        data-testid="tnc-checkbox"
        checked={tncAgreed}
        onCheckBoxChange={onTncCheckboxChange}
        description={renderTncCheckboxDescription()}
      />
    </Fragment>
  );
};

ConfirmConsent.defaultProps = {
  tncAgreed: false,
  onTncCheckboxChange: noop
};

ConfirmConsent.propTypes = {
  /** Flag to indicate tnc agreed status */
  tncAgreed: PropTypes.bool.isRequired,
  /** Handler to get notified when tnc checkbox checked */
  onTncCheckboxChange: PropTypes.func.isRequired
};

export default ConfirmConsent;
