import React from 'react';
import { Helmet } from 'react-helmet';
import ConfirmDetails from '../containers/ConfirmDetails';
import withLayout from '../components/withLayout';
import { ThemeProvider } from '@dls/web';

import { PRODUCT_NAME, CONFIRM_DETAILS } from '../constants/page_content.json';

const ConfirmDetailsPage = () => (
  <ThemeProvider brand="advance">
    <Helmet defaultTitle={PRODUCT_NAME} titleTemplate="%s">
      <title>{CONFIRM_DETAILS.TITLE}</title>
    </Helmet>
    <ConfirmDetails />
  </ThemeProvider>
);

export default withLayout(ConfirmDetailsPage);
