import CHARGES from '../constants/charges.json';

/**
 * Calculate the offers by getting the sum of promo prices
 *
 * @param {Object} offers  - List of promo offers
 * @returns {number} - Sum of the promo prices
 */
export const calcultateOffers = offers => {
  if (offers && offers.length) {
    const sum = offers.reduce((prev, cur) => {
      return prev + cur.promotedRate;
    }, 0);
    return sum;
  }
  return 0;
};

/**
 * If there is any promo added, return the plan price after discount. Otherwise
 * return the base plan price
 *
 * @param {number} basePlanPrice  - Base plan price
 * @param {number} priceAfterDiscount - Plan price after promo discount
 * @returns {number} - Plan price
 */
export const getPlanPrice = (basePlanPrice, priceAfterDiscount) => {
  const planPrice = priceAfterDiscount || basePlanPrice || 0;
  return Math.abs(planPrice).toFixed(2);
};

/**
 * Get one time charge display value prepended with currency symbol
 *
 * @param {number} oneTimeCharge  - One time charge
 * @returns {string} - One time charge display value
 */
export const getOneTimeChargeDisplayValue = oneTimeCharge => {
  let oneTimeChargeDiplayValue = null;
  if (!isNaN(oneTimeCharge)) {
    oneTimeChargeDiplayValue =
      oneTimeCharge == 0
        ? CHARGES.ONE_TIME_CHARGE.DISCOUNTED_CHARGE
        : (oneTimeCharge > 0 ? '' : '- ') +
          `${CHARGES.CURRENCY}${Math.abs(oneTimeCharge).toFixed(2)}`;
  }
  return oneTimeChargeDiplayValue;
};

/**
 * Show bonus data description if the offer description contains "bonus data*"
 *
 * @param {string} offerDescription - Offer description
 * @returns {boolean} - True if the offer description contains "bonus data*"
 */
export const showBonusDataDescription = offerDescription => {
  return !!offerDescription?.toLowerCase().includes(CHARGES.BONUS_DATA_NOTE);
};
